import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SSRProvider from "react-bootstrap/SSRProvider";

import BioModal from "../components/bioModal";
import ContentQuoteRight from "../components/contentQuoteRight.js";
import DefaultHeader from "../components/default-header";
import Footer from "../components/footer";
import GdprPanel from "../components/gdpr";
import IntegralVoicesAllEpisodes from "../components/integralVoicesAllEpisodes";
import IntegralVoicesLatestEpisode from "../components/integralVoicesLatestEpisode";
import IVContactForm from "../components/forms/IVcontactForm";
import {
  MoreWaysToListen,
  IVYoutube,
} from "../components/integralVoicesListen";
import Navigation from "../components/navigation";
import Seo from "../components/seo";

import "../styles/integral-voices.scss";
import NewsletterSignup from "../components/newsletterSignUp.js";

export default function Page({ data }) {
  const shortcodes = {
    ContentQuoteRight,
    IntegralVoicesLatestEpisode,
    IntegralVoicesAllEpisodes,
    MoreWaysToListen,
    IVYoutube,
    BioModal,
    Container,
    Row,
    Col,
  };

  return (
    <>
      <SSRProvider>
        <Navigation />
        <DefaultHeader
          type="iv"
          headline={data.mdx.frontmatter.headline}
          heroBackground={data.mdx.frontmatter.heroBackground}
          heroMobileBackground={data.mdx.frontmatter.heroMobileBackground}
          subHeadline={data.mdx.frontmatter.subheadline}
        />
        <div id="body" className="integral-voices">
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </MDXProvider>
          <div id="contactForm">
            <Container fluid="lg" className="py-4 py-xl-5">
              <Row className="py-4 py-xl-5 justify-content-center align-items-center">
                <Col lg={6} xl={5} md={10} className="order-lg-last">
                  <IVContactForm
                    submitCampaignID="7013i000000pm4hAAA"
                    submitType="contact"
                  />
                </Col>
                <Col
                  lg={6}
                  xl={{ span: 6, offset: 1 }}
                  md={10}
                  className="order-first order-lg-last mb-5 mb-lg-0"
                >
                  <img
                    src="https://web-cdn.meridianuniversity.edu/site-content-images/integral-voices/integral-voices-contact-form-image-2023.webp"
                    alt="people sitting outside on devices"
                    className="img-fluid"
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
        <GdprPanel />
        <NewsletterSignup />
      </SSRProvider>
    </>
  );
}

export const Head = ({ data }) => (
  <>
    <Seo
      title={data.mdx.frontmatter.metadata.Title}
      description={data.mdx.frontmatter.metadata.Description}
      pathname={data.mdx.frontmatter.metadata.Link}
      ogImage={data.mdx.frontmatter.metadata.ogImage}
      cardImage={data.mdx.frontmatter.metadata.cardImage}
    />
  </>
);

export const query = graphql`
  query ($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        heroBackground
        heroMobileBackground
        subheadline
        headline
        title
        metadata {
          Description
          Keywords
          Link
          Title
          ogImage
          cardImage
        }
      }
      body
    }
  }
`;
