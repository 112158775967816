import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles/contentQuoteRight.scss";

const ContentQuoteRight = ({
  quoteRight,
  quoteAuthorRight,
  quoteAuthorImgRight,
}) => {
  return (
    <div id="contentQuoteRight">
      <Row className="align-items-center justify-content-center content-quote pb-3">
        <Col lg={3} xs={6} className="p-3 p-lg-4 text-center">
          <img
            src={quoteAuthorImgRight}
            className="img-fluid quote-image"
            alt={`${quoteAuthorRight} thumbnail`}
            width="185"
            height="185"
            loading="auto"
          />
        </Col>
        <Col lg={8} xs={11} className="text-center text-lg-start">
          <p className="contentQuoteRightImage">"{quoteRight}"</p>
          <p className="quote-author">— {quoteAuthorRight}</p>
        </Col>
      </Row>
    </div>
  );
};

export default ContentQuoteRight;
